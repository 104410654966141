import "./styles.sass";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { customFetch } from "../../services/customFetch";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import Button from "../../components/button/button";
import Select from "../../components/select/select";

const Start = ({
  alertMessage,
  setCurrentPage,
  navigateAnimated,
  embedConfigs,
  setEmbedConfig,
  setSelectedReport,
  selectedReport,
  embedConfigsLoading,
  setEmbedConfigsLoading,
  embedConfig,
  setEmbedConfigs,
  ...props
}) => {
  const getEmbedInfosMID = "asdf651as5d3af35df15";
  const getEmbedInfos = async (callTime = 0) => {
    setEmbedConfigsLoading(true);

    customFetch({
      urlEnd: "/reports/get-reports/",
      method: "GET",
      navigate: navigateAnimated,
      alertMessage: alertMessage,
      alertMessageId: getEmbedInfosMID,
      onLoad: (_data, success) => {
        setEmbedConfigsLoading(false);
        if (_data && success) {
          setEmbedConfigs(_data);
        } else if (!success && _data !== "accessTokenExpired") {
          if (callTime !== 5) getEmbedInfos(callTime + 1);
        }
      },
    });
  };

  useEffect(() => {
    setCurrentPage("start");

    if (!embedConfigs) getEmbedInfos();
  }, []);

  useEffect(() => {}, []);
  return (
    <div className="powerbi-page start">
      {embedConfig ? (
        <PowerBIEmbed
          embedConfig={{
            ...embedConfig,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
            //   background: models.BackgroundType.Transparent,
            },
          }}
          cssClassName={"power-bi-embed-window"}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
        />
      ) : (
        <div className="no-reports">
          <Select
            options={(() => {
              const _reportIds = {};
              if (embedConfigs) {
                embedConfigs?.reportConfig.forEach((_report) => {
                  _reportIds[_report.reportId] = _report["reportName"].replace("_try", "").replaceAll("_", " ");
                });
              }

              return _reportIds;
            })()}
            selected={selectedReport}
            loading={embedConfigsLoading}
            className={"select-report"}
            enableSearch
            onSelect={(_selectedReportId) => {
              if (_selectedReportId && embedConfigs) {
                const _foundReport = embedConfigs?.reportConfig.filter((_report) => {
                  return _report.reportId === _selectedReportId[0];
                });
                if (_foundReport.length) {
                  const reportLoadConfig = {
                    type: "report",
                    tokenType: models.TokenType.Embed,
                  };
                  reportLoadConfig.accessToken = embedConfigs.accessToken;

                  // You can embed different reports as per your need
                  reportLoadConfig.embedUrl = _foundReport[0].embedUrl;

                  setEmbedConfig(reportLoadConfig);
                  setSelectedReport(_selectedReportId);
                }
              }
            }}
          >
            <Button outlined leftIcon={"expand_more"}>
              <b>Selected Report: </b>
              {selectedReport && selectedReport.length ? selectedReport[1] : "---"}
            </Button>
          </Select>
        </div>
      )}
    </div>
  );
};

export default Start;
