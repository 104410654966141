import './styles.sass'
import { memo, useEffect, useRef, useState } from 'react'
import Modal from '../../../../components/modal/modal'
import { customFetch } from '../../../../services/customFetch'
import TextField from '../../../../components/textfield/textfield'
import Checkbox from '../../../../components/checkbox/checkbox'
import Select from '../../../../components/select/select'
import Button from '../../../../components/button/button'

const ToggleAccountBlockingModal = ({
    show,
    setShow,
    reload,
    reportIds,
    accountData,
    setCurrentPage,
    navigateAnimated,
    alertMessage
}) => {
    const blocked = accountData?.blocked

    const closeModal = reloadingData => {
        if (reloadingData) reload()
        setShow()
    }
    const toggleAccountBlocking = () => {
        customFetch({
            urlEnd: '/panel/update/',
            method: 'PUT',
            body: {
                blocked: !blocked,
                userId: accountData.id
            },
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: '0baf5wd2-2905-4GR3-450f-86t7ee37dlc8',
            alertMessagesData: {
                loadingMessageText: `Account is being ${blocked ? 'unblocked' : 'blocked'}!`,
                successMessageText: `Account ${blocked ? 'unblocked successfully' : 'blocked successfully'}`,
                errorMessageText: `Account cannot be ${blocked ? 'unblocked' : 'blocked'}!`
            },
            onLoad: (_data, success) => {
                if (_data && _data.status && _data.status === 'success' && success) {
                    closeModal(true)
                }
            },
            onCatch: err => {
                console.log(err)
            }
        })
    }

    return (
        <Modal
            headline={`${blocked ? 'Unblock' : 'Block'} account`}
            show={show}
            submitText={blocked ? 'Unblock' : 'Block'}
            submitIcon={'check'}
            onSubmit={() => {
                toggleAccountBlocking()
            }}
            submitLoading={false}
            cancelText={'Cancel'}
            cancelIcon={'clear'}
            onCancel={() => {
                closeModal()
            }}
        >
            {blocked ? 'User will be unblocked' : 'User will be blocked'}.
        </Modal>
    )
}
export default ToggleAccountBlockingModal
