import './styles.sass'
import {useSearchParams} from 'react-router-dom'
import TextField from '../../components/textfield/textfield'
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {customFetch} from '../../services/customFetch'
import Button from '../../components/button/button'
import Checkbox from '../../components/checkbox/checkbox'
import Logo from '../../components/logo/logo'
import {passwordRegex} from "./login";


const SSOLogin = ({alertMessage, setCurrentPage, navigateAnimated, setUser}) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)
    const [accessToken, setAccessToken] = useState('')
    const usernameTextfieldRef = useRef()
    const firstTime = useRef({username: true, password: true})
    const checkAccessTokenMID = '773ssseeetsfasfdfasffe'
    const navigate = useNavigate()
    const [display, setDisplay] = useState(false)
    const [ssoToken, SetSsoToken] = useState((localStorage.getItem('ssoToken')) ? localStorage.getItem('ssoToken') : "")

    useEffect(() => {
        setCurrentPage('login')
        if (ssoToken !== "") {
            validateSsoToken();
        } else {
            setDisplay(true)
        }
    }, []);


    // Validate SSO token for SSO token
    const validateSsoToken = () => {
        customFetch({
            urlEnd: '/validate-sso-token/',
            method: 'POST',
            headers: {
                // ...(mfa
                //     ? {
                //         mfa: mfa,
                //         mfaAccessToken: accessToken
                //     }
                //     : {})
            },
            body: {
                token: ssoToken
            },
            withoutNavigateToLogin: true,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: checkAccessTokenMID,
            onLoad: (_data, success) => {
                if (_data.status_code === 400) {
                    setDisplay(true)
                }
                if (_data.userData) {
                    setLoggedIn(true)
                    localStorage.setItem('accessToken', _data.accessToken)
                    localStorage.setItem('refreshToken', _data.refreshAccessToken)
                    localStorage.setItem('ssoToken', _data.ssoToken)
                    localStorage.setItem('userData', JSON.stringify(_data.userData))
                    alertMessage({
                        message: `Welcome back, ${_data.userData.firstName}`,
                        status: 'success',
                        MID: checkAccessTokenMID,
                        dismissible: false,
                        timeoutInMilliseconds: 10000
                    })
                    setTimeout(() => {
                        navigate('/home')
                    }, 2000)
                }
            }
        })
    }

    const login = async mfa => {
        customFetch({
            urlEnd: '/login/',
            method: 'POST',
            headers: {
                ...(mfa
                    ? {
                        mfa: mfa,
                        mfaAccessToken: accessToken
                    }
                    : {})
            },
            body: {
                username: username,
                password: password
            },
            withoutNavigateToLogin: true,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: checkAccessTokenMID,
            onLoad: (_data, success) => {
                if (_data) {
                    if (success) {
                        // Save Data to localStorage
                        localStorage.setItem('accessToken', _data.accessToken)
                        localStorage.setItem('refreshToken', _data.refreshAccessToken)
                        localStorage.setItem('ssoToken', _data.ssoToken)
                        localStorage.setItem('userData', JSON.stringify(_data.userData))
                        alertMessage({
                            message: `Welcome back, ${_data.userData.firstName}`,
                            status: 'success',
                            MID: checkAccessTokenMID,
                            dismissible: false,
                            timeoutInMilliseconds: 10000
                        })
                        setUser(_data.userData)
                        setLoggedIn(true)
                        // setLoggedInFirstTime(false)
                        setTimeout(() => {
                            navigate('/home')
                        }, 2000)
                    }
                }
            }
        })
    }

    return (
        <>
            {display ? (<div className={`powerbi-page login${loggedIn ? ' logged-in' : ''}`}>
                    <div className={`login-container`}>
                        <div className="headline">
                            <div className={'logo-container'}>
                                <Logo/>
                            </div>
                            <p className={'header-text'}>
                                Use your IPG credentials here
                            </p>
                        </div>
                        <div className="login-content">
                            <div className={`forms`}>
                                <div className={`horizontal-movable-forms`}>
                                    <div className="left-movable-form">
                                        <div className={`form login-form`}>
                                            <div className={'text-fields'}>
                                                <TextField
                                                    value={username ? username : ''}
                                                    ref={usernameTextfieldRef}
                                                    placeholder={'Username'}
                                                    // onKeyDown={e => {
                                                    //     if (e.key === 'Enter' && username && password) {
                                                    //         // login()
                                                    //     }
                                                    // }}
                                                    onChange={_username => {
                                                        if (firstTime.current.username) firstTime.current.username = false
                                                        setUsername(_username)
                                                    }}
                                                    isInvalid={
                                                        !username.replace(/\s+/g, '') && password && !firstTime.current.username
                                                    }
                                                    invalidText={'Please enter your username'}
                                                />
                                                <hr/>
                                                <TextField
                                                    value={password ? password : ''}
                                                    placeholder={'Password'}
                                                    // onKeyDown={e => {
                                                    //     if (e.key === 'Enter' && username && password) {
                                                    //         // login()
                                                    //     }
                                                    // }}
                                                    type={'password'}
                                                    onChange={_password => {
                                                        if (firstTime.current.password) firstTime.current.password = false
                                                        setPassword(_password)
                                                    }}
                                                    isInvalid={username && !password && !firstTime.current.password}
                                                    invalidText={'Please enter password'}
                                                />
                                            </div>
                                            <div
                                                className="login-button"
                                                onClick={() => {
                                                    if (username.replaceAll(' ', '') && password) {
                                                        login()
                                                    }
                                                }}
                                            >
                                                <span className="material-symbols-outlined"> navigate_next </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
                : (
                    ''
                )
            }
        </>
    )
}

export default SSOLogin
