import './styles.sass'
import { memo, useEffect, useRef, useState } from 'react'
import Modal from '../../../../components/modal/modal'
import { customFetch } from '../../../../services/customFetch'

const DeleteAccountModal = ({ show, setShow, accountId, reload, setCurrentPage, navigateAnimated, alertMessage }) => {
    const deleteAccount = _accountId => {
        customFetch({
            urlEnd: '/panel/delete/',
            method: 'DELETE',
            // headers: {
            //     userId: _accountId
            // },
            body: {
                userId: _accountId
            },
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: '0baf5wd2-2905-4GR3-450f-86t7ee37dlc8',
            alertMessagesData: {
                loadingMessageText: 'Account is being deleted!',
                successMessageText: 'Account deleted successfully',
                errorMessageText: 'Account cannot be deleted!'
            },
            onLoad: (_data, success) => {
                if (_data && _data.status && _data.status === 'success' && success) {
                    setShow()
                    reload()
                }
            },
            onCatch: err => {
                console.log(err)
            }
        })
    }

    return (
        <Modal
            headline={'Delete account'}
            show={show}
            submitText={'Delete'}
            submitIcon={'check'}
            onSubmit={() => {
                if (accountId) deleteAccount(accountId)
            }}
            submitLoading={false}
            cancelText={'Cancel'}
            cancelIcon={'clear'}
            onCancel={() => {
                setShow()
            }}
        >
            Alle nicht gespeicherten Daten gehen verloren.
        </Modal>
    )
}
export default DeleteAccountModal
