import './styles.sass'
import {memo, useEffect, useRef, useState} from 'react'
import {customFetch} from '../../../services/customFetch'
import Modal from '../../../components/modal/modal'
import TextField from '../../../components/textfield/textfield'
import Checkbox from '../../../components/checkbox/checkbox'
import {passwordRegex} from '../../login/login'

const ChnageMyDataModal = ({
                               show,
                               setShow,
                               reload,
                               userData,
                               setUserData,
                               setCurrentPage,
                               navigateAnimated,
                               alertMessage,
                               setEmbedConfigs,
                               setEmbedConfig
                           }) => {
    const [newAccountData, setNewAccountData] = useState({
        firstName: '',
        lastName: '',
        currentPassword: '',
        newPassword: ''
    })
    const [username, setUsername] = useState()

    const [changePassword, setChangePassword] = useState()
    const [currentPassword, setCurrentPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [newPasswordStrength, setNewPasswordStrength] = useState()
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState()

    useEffect(() => {
        if (userData)
            setNewAccountData({
                firstName: userData.firstName,
                lastName: userData.lastName,
                currentPassword: '',
                newPassword: '',
                isLdapEnabled: userData.isLdapEnabled
            })
    }, [userData])

    const closeModal = reloadingData => {
        if (reloadingData) reload()
        setShow()
        setTimeout(() => {
            setChangePassword()
            setNewPassword()
            setNewPasswordConfirmation()
            setNewPasswordStrength()
            setCurrentPassword()
            setNewAccountData({
                firstName: userData.firstName,
                lastName: userData.lastName,
                currentPassword: '',
                newPassword: '',
                isLdapEnabled: userData.isLdapEnabled
            })
        }, 550)
    }
    const editAccount = () => {
        customFetch({
            urlEnd: '/user/',
            method: 'PUT',
            body: {
                firstName: newAccountData.firstName,
                lastName: newAccountData.lastName,
                userId: userData.id,
                ...(changePassword
                    ? {
                        currentPassword: newAccountData.currentPassword,
                        newPassword: newAccountData.newPassword
                    }
                    : {})
            },
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: '0baf5wd2-2905-4GR3-450f-86t7ee37dlc8',
            alertMessagesData: {
                loadingMessageText: 'Account is being updated!',
                successMessageText: 'Account updated successfully',
                errorMessageText: 'Account cannot be updated!'
            },
            onLoad: (_data, success) => {
                if (_data && Object.keys(_data.userData).includes('username') && success) {
                    setShow()
                    setTimeout(() => {
                        localStorage.setItem('userData', JSON.stringify(_data.userData))
                        setUserData(_data.userData)
                        closeModal()
                        if (changePassword) {
                            setEmbedConfigs()
                            setEmbedConfig()
                            localStorage.removeItem('accessToken')
                            localStorage.removeItem('refreshToken')
                            localStorage.removeItem('userData')
                            window.location.reload()
                        }
                    }, 750)
                }
            },
            onCatch: err => {
                console.log(err)
            }
        })
    }

    useEffect(() => {
        if (
            newPassword &&
            ['Medium', 'Strong'].includes(newPasswordStrength) &&
            newPassword === newPasswordConfirmation
        )
            setNewAccountData(prevNewAccountData => ({
                ...prevNewAccountData,
                newPassword: newPassword
            }))
    }, [newPassword, newPasswordConfirmation, newPasswordStrength])

    return (
        <Modal
            className={`edit-account-modal`}
            headline={'Change my data'}
            show={show}
            submitIcon={'check'}
            disabledSubmit={
                !(newAccountData.firstName?.replaceAll(' ', '') || newAccountData.lastName?.replaceAll(' ', '')) ||
                (changePassword && !newAccountData.currentPassword?.replaceAll(' ', ''))
            }
            onSubmit={editAccount}
            submitLoading={false}
            cancelText={'Cancel'}
            cancelIcon={'clear'}
            onCancel={() => {
                setShow(false)
                closeModal()
            }}
        >
            <TextField
                placeholder={'Firstname'}
                autoComplete="off"
                value={newAccountData.firstName ? newAccountData.firstName : ' '}
                isInvalid={!newAccountData.firstName?.replaceAll(' ', '')}
                onChange={_value => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        firstName: _value
                    }))
                }}
            />
            <TextField
                placeholder={'Lastname'}
                name={'Lastname'}
                role="presentation"
                autoComplete="new-password"
                value={newAccountData.lastName ? newAccountData.lastName : ''}
                isInvalid={!newAccountData.lastName?.replaceAll(' ', '')}
                onChange={_value => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        lastName: _value
                    }))
                }}
            />
            <hr/>
            {/* commented because ldap  */}
            {/*<Checkbox checked={changePassword} onCheck={setChangePassword}>*/}
            {/*    Change password*/}
            {/*</Checkbox>*/}
            {changePassword ? (
                <>
                    <TextField
                        value={currentPassword ? currentPassword : ''}
                        placeholder={'Current password'}
                        role="presentation"
                        autoComplete="new-password"
                        type={'password'}
                        onChange={_password => {
                            setCurrentPassword(_password)
                            setNewAccountData(prevNewAccountData => ({
                                ...prevNewAccountData,
                                currentPassword: _password
                            }))
                        }}
                    />
                    <TextField
                        value={newPassword ? newPassword : ''}
                        className={newPasswordStrength}
                        placeholder={'New password'}
                        role="presentation"
                        autoComplete="new-password"
                        type={'password'}
                        onChange={_password => {
                            setNewPassword(_password)
                            if (passwordRegex('strong', userData?.isAdmin).test(_password))
                                setNewPasswordStrength('Strong')
                            else if (passwordRegex('medium', userData?.isAdmin).test(_password))
                                setNewPasswordStrength('Medium')
                            else setNewPasswordStrength('Weak')
                        }}
                        isInvalid={newPassword && ['Weak'].includes(newPasswordStrength)}
                        invalidText={'Password strength: ' + newPasswordStrength}
                    />
                    <TextField
                        value={newPasswordConfirmation ? newPasswordConfirmation : ''}
                        className={newPassword === newPasswordConfirmation ? 'matched' : ''}
                        placeholder={'Confirm new password'}
                        role="presentation"
                        autoComplete="new-password"
                        type={'password'}
                        onChange={_password => {
                            setNewPasswordConfirmation(_password)
                        }}
                        isInvalid={newPassword !== newPasswordConfirmation}
                        invalidText={
                            newPassword !== newPasswordConfirmation
                                ? 'Confirmation password not matched!'
                                : 'Password confirmed'
                        }
                    />
                    <div className="new-password-strength-hints">
                        <ul>
                            <li>
                                <Checkbox checked={newPassword?.length >= (userData?.isAdmin ? 15 : 10)}>
                                    {userData?.isAdmin ? 15 : 10} characters at least
                                </Checkbox>
                            </li>
                            <li>
                                <Checkbox checked={newPassword?.match(/[A-Z]+/g)}>1x uppercase letter</Checkbox>
                            </li>
                            <li>
                                <Checkbox checked={newPassword?.match(/\d+/g)}>1x number</Checkbox>
                            </li>
                            <li>
                                <Checkbox checked={newPassword?.match(/[!@#$%^&*]+/g)}>1x special character</Checkbox>
                            </li>
                        </ul>
                    </div>
                </>
            ) : null}
        </Modal>
    )
}
export default ChnageMyDataModal
