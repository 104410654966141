import { v4 as uuid4 } from 'uuid'
import { getFP } from './getFingerPrint'

export const customFetch = async ({
    urlEnd,
    method = 'GET',
    headers,
    body,
    navigate,
    onLoad,
    onCatch,
    alertMessage,
    alertMessageId = uuid4(),
    alertMessagesData,
    withoutNavigateToLogin
}) => {
    const _hostname = window.location.hostname
    // const url = `${window ? window.location.protocol : 'http:'}//${
    //     process.env.REACT_APP_BE ?? window
    //         ? _hostname.includes('localhost') || _hostname.includes('192')
    //             ? `${_hostname}:5002`
    //             : _hostname
    //         : ''
    // }`

    const url = process.env.REACT_APP_BE
    // console.log(process.env.REACT_APP_BE)
    // console.log(url)

    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken && !withoutNavigateToLogin) navigate('/login')

    if (alertMessagesData)
        alertMessage({
            message: alertMessagesData.loadingMessageText,
            status: 'warning',
            MID: alertMessageId,
            dismissible: false
        })

    const call = xsrfToekn => {
        const requestOptions = {
            method: method,
            headers: {
                accessToken: accessToken,
                'Content-type': 'application/json',
                'XSRF-TOKEN': xsrfToekn,
                Accept: 'application/json',
                fp: getFP(),
                ...headers
            },
            body: JSON.stringify(body)
        }

        fetch(url + `${urlEnd}`, requestOptions)
            .then(response => {
                if (response.ok) return response.json()
                if (alertMessagesData)
                    alertMessage({
                        message: alertMessagesData.errorMessageText,
                        status: 'danger',
                        MID: alertMessageId,
                        timeoutInMilliseconds: 10000,
                        dismissible: true
                    })
                return null
            })
            .then(d => {
                let success = false
                if (
                    d &&
                    (!Object.keys(d).includes('message') ||
                        (Object.keys(d).includes('message') && d.status === 'success'))
                ) {
                    if (alertMessagesData)
                        alertMessage({
                            message: alertMessagesData.successMessageText,
                            status: 'success',
                            MID: alertMessageId,
                            timeoutInMilliseconds: 10000,
                            dismissible: true
                        })
                    success = true
                } else if (d && Object.keys(d).includes('message') && d.status === 'danger') {
                    alertMessage({
                        message: d.message,
                        status: 'danger',
                        MID: alertMessageId,
                        timeoutInMilliseconds: 10000,
                        dismissible: true
                    })
                    if (
                        ['Invalid accessToken!!!', 'AccessToken was expired!!!'].includes(d.message) &&
                        !withoutNavigateToLogin
                    ) {
                        localStorage.removeItem('accessToken')
                        localStorage.removeItem('refreshToken')
                        localStorage.removeItem('userData')
                        navigate('/login')
                        onLoad('accessTokenExpired', success)
                        return
                    }
                } else {
                    if (alertMessagesData)
                        alertMessage({
                            message: alertMessagesData.errorMessageText,
                            status: 'danger',
                            MID: alertMessageId,
                            timeoutInMilliseconds: 10000,
                            dismissible: true
                        })
                }
                onLoad(d, success)
            })
            .catch(e => {
                if (alertMessagesData)
                    alertMessage({
                        message: alertMessagesData.errorMessageText,
                        status: 'danger',
                        MID: alertMessageId,
                        timeoutInMilliseconds: 10000,
                        dismissible: true
                    })
                if (onCatch) onCatch(e)
            })
    }

    // if (_hostname.includes('localhost') || _hostname.includes('192')) {
    //     call(null)
    // } else {
    //     // fetch xsrf token
    //     fetch(url + '/reg', {
    //         method: 'GET',
    //         headers: {
    //             'Content-type': 'application/json'
    //         }
    //     })
    //         .then(response => {
    //             if (response.ok) return response.text()
    //         })
    //         .then(xsrfToekn => {
    //             call(xsrfToekn)
    //         })
    // }

    call(null);
}
