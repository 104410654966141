import "./styles.sass";
import Modal from "../../../../components/modal/modal";
import { customFetch } from "../../../../services/customFetch";

const ToggleAccountActiveModal = ({ show, setShow, reload, accountData, navigateAnimated, alertMessage }) => {
  const active = accountData?.isActive;

  const closeModal = (reloadingData) => {
    if (reloadingData) reload();
    setShow();
  };
  const toggleAccountActive = () => {
    customFetch({
      urlEnd: "/panel/update/",
      method: "PUT",
      body: {
        isActive: !active,
        userId: accountData.id,
      },
      navigate: navigateAnimated,
      alertMessage: alertMessage,
      alertMessageId: "0baf5wd2-2905-4GR3-450f-86t7ee37dlc9",
      alertMessagesData: {
        loadingMessageText: `Account is being ${!active ? "set active" : "set not active"}!`,
        successMessageText: `Account ${active ? "set active successfully" : "set not active successfully"}`,
        errorMessageText: `Account cannot be ${!active ? "set active" : "set not active"}!`,
      },
      onLoad: (_data, success) => {
        if (_data && _data.status && _data.status === "success" && success) {
          closeModal(true);
        }
      },
      onCatch: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <Modal
      headline={`${!active ? "Set active" : "Set not active"} account`}
      show={show}
      submitText={!active ? "Set active" : "Set not active"}
      submitIcon={"check"}
      onSubmit={() => {
        toggleAccountActive();
      }}
      submitLoading={false}
      cancelText={"Cancel"}
      cancelIcon={"clear"}
      onCancel={() => {
        closeModal();
      }}
    >
      {!active ? "User will be set active" : "User will be set not active"}.
    </Modal>
  );
};
export default ToggleAccountActiveModal;
