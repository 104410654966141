import "./styles.sass";
import { useEffect, useState } from "react";
import CreateNewAccountModal from "./modals/create-new-account/createNewAccountModal";
import EditAccountModal from "./modals/edit-account/editAccountModal";
import Button from "../../components/button/button";
import { customFetch } from "../../services/customFetch";
import DeleteAccountModal from "./modals/delete-account/deleteAccountModal";
import ToggleAccountBlockingModal from "./modals/toggle-account-blocking/toggleAccountBlockingModal";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Textfield from "../../components/textfield/textfield";
import ToggleAccountActiveModal from "./modals/toggle-account-active/toggleAccountActiveModal";

const Admin = ({ setCurrentPage, navigateAnimated, alertMessage, user }) => {
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [reportIds, setReportIds] = useState();
  const [showAddAccountModal, setShowAddAccountModal] = useState();
  const [editAccount, setEditAccount] = useState();
  const [deleteAccount, setDeleteAccount] = useState();
  const [toggleAccountBlocking, setToggleAccountBlocking] = useState();
  const [toggleAccountActive, setToggleAccountActive] = useState();

  const getAccountsMID = "asdfl88z87aisuzfas7zf";
  const getAccounts = async () => {
    customFetch({
      urlEnd: "/panel/get-admins/",
      method: "GET",
      withoutNavigateToLogin: true,
      navigate: navigateAnimated,
      alertMessage: alertMessage,
      alertMessageId: getAccountsMID,
      onLoad: (_data, success) => {
        if (_data && _data.length && success) {
          setAccounts(_data);
          setFilteredAccounts(_data);
        } else {
          setTimeout(() => {
            getAccounts();
          }, 1000);
        }
      },
    });
  };
  const getReportIds = async (callTime = 0) => {
    customFetch({
      urlEnd: "/reports/get-reports-ids/",
      method: "GET",
      navigate: navigateAnimated,
      alertMessage: alertMessage,
      alertMessageId: getAccountsMID,
      onLoad: (_data, success) => {
        if (_data && success) {
          setReportIds(_data);
        } else if (!success && _data !== "accessTokenExpired") {
          if (callTime !== 5) setReportIds(callTime + 1);
        }
      },
    });
  };

  useEffect(() => {
    setCurrentPage("admin");
    if (user)
      if (!user.isAdmin) {
        navigateAnimated("/home");
      } else {
        getAccounts();
        getReportIds();
      }
  }, [user]);

  const handleCSVExporter = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "users" });

    const mockData = accounts.map((account) => {
      return {
        Nachname: account.lastName,
        Vorname: account.firstName,
        Anzeigename: `${account.lastName}.${account.firstName}`,
        Erstellungsdatum: account.createdAt,
        Deaktieviert: account.blocked ? "Yes" : "No",
        Deaktievierungsdatum: account.blockedAt,
        Rolle: account.isAdmin ? "admin" : "user",
        Änderungsdatum: account.updatedAt ?? "-",
        "Geändert durch": account.updatedFrom,
        Benutzertyp: account.isLdapEnabled ? "Internal" : "External",
        Benutzerrechte: Object.entries(reportIds)
          .filter(([k, v]) => account?.reportIds?.includes(k) || account.isAdmin)
          .map(([k, v]) => v)
          .join("|||"),
      };
    });

    const csv = generateCsv(csvConfig)(mockData);

    download(csvConfig)(csv);
  };

  return (
    <div className={`powerbi-page admin`}>
      <div className={`content${showAddAccountModal || editAccount || deleteAccount || toggleAccountBlocking ? " modal-is-open" : ""}`}>
        <div className="header-navbar">
          <h1>Admin Panel</h1>
          <div className={"header-navbar-buttons-container"}>
            <Button
              leftIcon={"add"}
              outlined
              green
              onClick={() => {
                setShowAddAccountModal(true);
              }}
            >
              Create new Account
            </Button>
            <Button
              leftIcon={"download"}
              outlined
              onClick={() => {
                handleCSVExporter();
              }}
            >
              Export Users
            </Button>
          </div>
        </div>
        <Textfield
          onChange={(a) =>
            setFilteredAccounts(() =>
              accounts.filter((x) => x.username?.toLowerCase().includes(a?.toLowerCase()) || x.email?.toLowerCase().includes(a?.toLowerCase()))
            )
          }
        />
        <div className="accounts">
          {filteredAccounts && filteredAccounts.length
            ? filteredAccounts
                // .filter((account) => account.id !== user.id)
                .map((account, accountIndex) => {
                  const blocked = account.blocked;
                  const active = account.isActive;
                  return (
                    <div className="account" key={accountIndex}>
                      <h3 className="username">
                        <span style={{ color: account.isAdmin ? "green" : "" }} className="material-symbols-outlined">
                          {account.isAdmin ? "crown" : "account_circle"}
                        </span>
                        <label>{`${account.email} (${account.username})`}</label>
                        <span className={`badge ${account.isLdapEnabled ? "badge-green" : "badge-red"}`}>
                          {account.isLdapEnabled ? "Internal" : "External"}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            color: checkIfDiffToLongFromToday(new Date(account.lastLogin), 30) ? "red" : "",
                          }}
                        >
                          <label>Last login</label>
                          <label>{account.lastLogin}</label>
                        </div>
                      </h3>
                      <div className="action-buttons">
                        <Button
                          className={"block-unblock-button"}
                          leftIcon={active ? "person_off" : "person"}
                          green={!active}
                          red={active}
                          outlined
                          onClick={() => {
                            setToggleAccountActive(account);
                          }}
                        >
                          {!active ? "Set active" : "Set not active"}
                        </Button>
                        <Button
                          className={"block-unblock-button"}
                          leftIcon={blocked ? "person_off" : "person"}
                          green={blocked}
                          red={!blocked}
                          outlined
                          onClick={() => {
                            setToggleAccountBlocking(account);
                          }}
                        >
                          {blocked ? "Unblock" : "Block"}
                        </Button>
                        <Button
                          leftIcon={"delete"}
                          outlined
                          red
                          onClick={() => {
                            setDeleteAccount(account.id);
                          }}
                        />
                        <Button
                          leftIcon={"edit"}
                          outlined
                          blue
                          onClick={() => {
                            setEditAccount(account);
                          }}
                        />
                      </div>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
      <CreateNewAccountModal
        show={showAddAccountModal}
        setShow={setShowAddAccountModal}
        reload={getAccounts}
        reportIds={reportIds}
        setCurrentPage={setCurrentPage}
        navigateAnimated={navigateAnimated}
        alertMessage={alertMessage}
      />
      <EditAccountModal
        show={editAccount}
        setShow={setEditAccount}
        accountData={editAccount}
        reload={getAccounts}
        reportIds={reportIds}
        setCurrentPage={setCurrentPage}
        navigateAnimated={navigateAnimated}
        alertMessage={alertMessage}
      />
      <DeleteAccountModal
        show={deleteAccount}
        setShow={setDeleteAccount}
        accountId={deleteAccount}
        reload={getAccounts}
        setCurrentPage={setCurrentPage}
        navigateAnimated={navigateAnimated}
        alertMessage={alertMessage}
      />
      <ToggleAccountBlockingModal
        show={toggleAccountBlocking}
        setShow={setToggleAccountBlocking}
        accountData={toggleAccountBlocking}
        reload={getAccounts}
        reportIds={reportIds}
        setCurrentPage={setCurrentPage}
        navigateAnimated={navigateAnimated}
        alertMessage={alertMessage}
      />
      <ToggleAccountActiveModal
        show={toggleAccountActive}
        setShow={setToggleAccountActive}
        accountData={toggleAccountActive}
        reload={getAccounts}
        reportIds={reportIds}
        setCurrentPage={setCurrentPage}
        navigateAnimated={navigateAnimated}
        alertMessage={alertMessage}
      />
    </div>
  );
};

const checkIfDiffToLongFromToday = (otherDate, n) => {
  const dateNow = new Date();
  return Math.floor((dateNow - otherDate) / (1000 * 60 * 60 * 24)) >= n;
};

export default Admin;
